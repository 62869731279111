import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from '../components/Header';
import Index from './Index';
import Works from './Works';

const App = () => {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path='/' element={<Index />} />
                <Route path='/trabajos' element={<Works />} />
            </Routes>
        </BrowserRouter>

    )
}

export default App