import './Header.css'
import { NavLink, Link } from 'react-router-dom'

const Header = () => {
    return (
        <header className='container d-flex'>
            <h1 className='logo'>
                <Link to='/'><img src='./assets/img/logo.svg' width={50} height={50} /></Link>
            </h1>
            <nav>
                <ul className='d-flex'>
                    <li><NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/'>Inicio</NavLink></li>
                    <li><NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/trabajos'>Trabajos</NavLink></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header