import { LazyLoadImage } from 'react-lazy-load-image-component';
import './WorkItem.css';

const WorkItem = (props) => {
    const {work} = props;
    return (
        <article data-id={work.id} className={work.destacado == "1" ? "work destacado" : "work"} onClick={()=>props.onPress(work)}>
            <div className='info'>
                <h2>{work.anio} | {work.tipo}</h2>
                <h1>{work.nombre}</h1>
            </div>
            <LazyLoadImage src={work.imagen} alt={work.nombre} />
        </article>
    )
}

export default WorkItem