import './WorkModal.css';

const WorkModal = (props) => {
    const { work } = props;
    return (
        <>
            <div className="modal fade show">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <button className='close' onClick={() => props.onCloseModal()}><i className='bi bi-x-circle'></i></button>

                        <div className="modal-header">
                            <h1>{work.nombre}</h1>
                        </div>
                        <div className='modal-body'>
                            <div className='px-4'>
                                <div className="row">
                                    <span className="col-lg-6">
                                        <p><i className='bi bi-kanban-fill'></i> Proyecto: <strong>{work.tipo}</strong></p>
                                        <p><i className='bi bi-calendar2'></i> Año: <strong>{work.anio}</strong></p>
                                    </span>
                                    <span className="col-lg-6">
                                        <p><i className='bi bi-code'></i> Tecnología: <strong>{work.tecnologia}</strong></p>
                                        <p><i className='bi bi-person-fill'></i> Cargo: <strong>{work.estudio}</strong></p>
                                    </span>
                                </div>
                                <div className='mt-4' dangerouslySetInnerHTML={{ __html: work.descripcion }} />
                                {
                                    work.url == "" ? "" : (<p><br /><a href={work.url} target="_blank" className='btn btn-dark'><i className='bi bi-link-45deg'></i> Ver trabajo</a></p>)
                                }
                            </div>
                            <p className='mt-4'><img src={work.imagen_work} alt={work.nombre} /></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export default WorkModal