import axios from 'axios'
import { useEffect, useState } from 'react';
import { useForm } from '../hooks/useForm';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import WorkItem from '../components/WorkItem';
import WorkModal from '../components/WorkModal';

import './Works.css';

const Works = () => {

    const apiEndPoint = 'http://www.gastongimenez.com/admin/ws/works.php';

    const [loading, setLoading] = useState(false);

    const [works, setWorks] = useState([]);
    const [filtro, setFiltro] = useState('all');

    const [modalContent, setModalContent] = useState([]);

    // Genero la función onsubmit
    const handleSubmit = async e => {
        e.preventDefault();
        setFiltro(busqueda);
    }

    useEffect(()=>{
        const getWorks = async()=>{
            setLoading(true);
            const response = await axios.get(apiEndPoint);
            setWorks(response.data);
            setLoading(false);
        }
        getWorks();
    }, []);

    const [values, handleInputChange] = useForm({busqueda: ''});
    const { busqueda } = values;

    const filtrarWorks = (work)=>{
        let {nombre, tipo, tecnologia, destacado} = work;
        nombre = nombre.toLowerCase();
        tipo = tipo.toLowerCase();
        tecnologia = tecnologia.toLowerCase();

        switch(filtro){
            case 'destacados':
                return (destacado=='1');
                break;
            
            case 'php':
                return (tecnologia.includes('php'));
                break;
            
            case 'js':
                return (tecnologia.includes('js') || tecnologia.includes('jquery'));
                break;
            
            case 'otros':
                return !(tecnologia.includes('php') || tecnologia.includes('js') || tecnologia.includes('jquery'));
                break;

            case 'all':
                return true;
                break;

            default:
                return (nombre.includes(filtro) || tipo.includes(filtro) || tecnologia.includes(filtro));
                break;
        }
    }

    const openModal = (work)=>{
        setModalContent([<WorkModal key={work.id} work={work} onCloseModal={closeModal} />]);
        document.querySelector("body").classList.add("modal-open");
    }

    const closeModal = ()=>{
        setModalContent([]);
        document.querySelector("body").classList.remove("modal-open");
    }

    return (
        <main id='works' className='container'>
            <h3>Trabajos</h3>

            <form className='row' onSubmit={handleSubmit}>
                <div className='col-xl-3'>
                    <div className='input-group'>
                        <input type='search' placeholder='Buscar trabajo' id='busqueda' name='busqueda' value={busqueda} onChange={handleInputChange}  />
                        <button><i className='bi bi-search'></i></button>
                    </div>
                </div>

                <div className='col-xl-9'>
                    <ul className='nav'>
                        <li className='nav-item'>
                            <a className={filtro=='all'? 'nav-link active':'nav-link'} onClick={()=>setFiltro('all')}>Todos</a>
                        </li>
                        <li className='nav-item'>
                            <a className={filtro=='destacados'? 'nav-link active':'nav-link'} onClick={()=>setFiltro('destacados')}>Destacados</a>
                        </li>
                        <li className='nav-item'>
                            <a className={filtro=='php'? 'nav-link active':'nav-link'} onClick={()=>setFiltro('php')}>PHP</a>
                        </li>
                        <li className='nav-item'>
                            <a className={filtro=='js'? 'nav-link active':'nav-link'} onClick={()=>setFiltro('js')}>JS</a>
                        </li>
                        <li className='nav-item'>
                            <a className={filtro=='otros'? 'nav-link active':'nav-link'} onClick={()=>setFiltro('otros')}>Otros</a>
                        </li>
                    </ul>
                </div>
            </form>

            <section id='modal'>{modalContent}</section>

            <section id='results'>
                {
                    loading?
                        (<p><img src='/assets/img/loading.svg' alt='Loading...'/></p>):(
                        works.filter(filtrarWorks).length>0? (

                            <div>
                                <h6 className='mb-4'>Se encontraron {works.filter(filtrarWorks).length} resultados.</h6>

                                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 1200: 3}}>
                                    <Masonry gutter={"1rem"}>
                                        {
                                            works.filter(filtrarWorks).map(work => (
                                                <WorkItem key={work.id} work={work} onPress={openModal} />
                                            ))
                                        }
                                    </Masonry>
                                </ResponsiveMasonry>
                            </div>
                        ) : (
                            <h6>No se encontraron resultados para <strong>'{filtro}'</strong>.</h6>
                        )
                    )
                }
            </section>
            
        </main>
    )
}

export default Works